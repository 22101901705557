export const Images = {
  icBackground: require('./images/Background.png'),
  icLogo: require('./images/Logo.png'),
  icUserImage: require('./images/userImage.png'),
  icEdit: require('./images/edit.png'),
  icClose: require('./images/close.png'),
  icMatrixBg: require('./images/matrix-bg.png'),
  icLock: require('./images/lock.png'),
  icSend: require('./images/send.png'),
  icTrash: require('./images/trash.png'),
  icUpload: require('./images/upload.png'),
  icCheck: require('./images/check.png'),
  icYes: require('./images/yes.png'),
  icNo: require('./images/no.png'),
  icReset: require('./images/reset.png'),
  icCopy: require('./images/copy.png'),
  icPlus: require('./images/plus-circle.png'),
  icLightLogo: require('./images/light-logo.png'),
  icLogoSymania: require('./images/dark-logo.png'),
  icLockUser: require('./images/LockIcon.png'),
  icActiveEye: require('./images/ActiveEye.png'),
  icInactiveEye: require('./images/InactiveEye.png'),
  icMobile: require('./images/mobile-icon.png'),
  icCloseBtn: require('./images/close-icon.png'),
  icHiHand: require('./images/hihand-icon.png'),
  icOkay: require('./images/okay-icon.png'),
  icArrow: require('./images/arrow-icon.png'),
  icRefresh: require('./images/refresh-icon.png'),
}
