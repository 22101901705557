import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthLayout from 'src/layouts/AuthLayout'
import { KeyboardBackspace } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import { ContainedButton } from 'src/components/Button'
import {
  __DEV__,
  countries,
  testingEmail,
  testingFirstName,
  testingLastName,
  testingPhone,
} from 'src/share/constants'
import { useMutation } from 'react-query'
import {
  addEmailFn,
  addPhoneFn,
  registerUserFn,
  validatePhoneFn,
  validateRegisterEmailFn,
} from 'src/api'
import OtpInput from 'react18-input-otp'
import { Autocomplete } from '@mui/material'
import { useAuth, User } from 'src/providers/AuthProvider'
import { Images } from 'src/assets'
import { validateEmailField } from 'src/share/utils'
import { motion } from 'framer-motion'

interface IStep {
  step: number
  setStep(step: number): void
}

const StepContext = createContext<IStep>({
  step: 1,
  setStep: () => {
    return
  },
})

interface RegisterStepProps {
  handleReset: () => void
  handleResetValues: (val: number) => void
}

function RegisterStep({ handleReset, handleResetValues }: RegisterStepProps) {
  const navigate = useNavigate()
  const { step, setStep } = useContext(StepContext)
  return (
    <div className="flex flex-col mb-8">
      <div
        className="flex text-lg mb-10"
        style={{ justifyContent: 'space-between' }}
      >
        <div>
          <Button
            onClick={() => {
              navigate(-1)
              return
            }}
            className="text-lg p-0"
            startIcon={<KeyboardBackspace />}
          >
            Back to login
          </Button>
        </div>
        {step > 0 && step < 4 ? (
          <p style={{ color: '#93929B' }}>Step {step} of 3</p>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Typography
          className="text-black"
          style={{
            fontWeight: '700',
            fontSize: '28px',
            marginBottom: '10px',
          }}
        >
          {step === 1
            ? 'Please enter your phone number'
            : step === 2
            ? 'Welcome'
            : step === 3
            ? 'Please enter your email address'
            : step === 4
            ? 'Email verification'
            : ''}
        </Typography>
      </div>
      <Typography
        paragraph
        className="text-black text-lg w-[440px]"
        style={{ fontSize: 18, marginBottom: '10px' }}
      >
        {step === 1
          ? 'We need your phone number to finalize your identity verification'
          : step === 2
          ? 'Let’s get started by entering your full name'
          : step === 3
          ? 'We need your email to finalize your identity verification.'
          : step === 4
          ? 'A message with a verification link has been sent to your email addres'
          : ''}
      </Typography>
    </div>
  )
}

function RegisterPage() {
  const [step, setStep] = useState(__DEV__ ? 6 : 1)
  const [userId, setUserId] = useState<number | null>(null)
  const [first_name, setFirst_name] = useState(__DEV__ ? testingFirstName : '')
  const [last_name, setLast_name] = useState(__DEV__ ? testingLastName : '')
  const [email, setEmail] = useState(__DEV__ ? testingEmail : '')
  const [unique, setUnique] = useState('')
  const [phoneCode, setPhoneCode] = useState('')
  const [country_code, setCountry_code] = useState(
    __DEV__ ? '31' : countries[0].phone
  )
  const [phoneNumber, setPhone_number] = useState(__DEV__ ? testingPhone : '')
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      alert('Session timed out. Please start again.')
      window.location.reload()
    }, 300000)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const isValid = validateEmailField(email)
    if (isValid === 'Invalid email' || isValid === 'Required') {
      setIsEmailValid(false)
    } else {
      setIsEmailValid(true)
    }
  }, [email])

  const currentUrl = window.location.href

  const token = currentUrl.split('/')[4]
  const { login } = useAuth()

  const { mutate: validateEmail } = useMutation(validateRegisterEmailFn, {
    onSuccess: (data) => {
      const userData: User = data.data
      login(token, (userData) => {
        navigate(`/${userData.id}/personal/settings/profile`)
      })
    },
    onError: (error: any) => {
      alert(error.response.data.message)
    },
  })

  if (token) {
    validateEmail(token)
  }

  if (step === 3) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  const navigate = useNavigate()
  function handleNavigateLogin() {
    navigate('/')
  }

  function handleSubmitPhoneCode() {
    validatePhone({
      unique,
      code: phoneCode,
    })
  }
  const { mutate: emailMutate, isLoading: emailLoading } = useMutation(
    addEmailFn,
    {
      onSuccess(data, variables, context) {
        setUnique(data)
      },
    }
  )
  const { mutate: validatePhone, isLoading: isValidatingPhone } = useMutation(
    validatePhoneFn,
    {
      onSuccess: () => {
        setPhoneCode('')
        setStep(3)
      },
      onError: (error: any) => {
        setPhoneCode('')
        alert(error.response.data.message)
      },
    }
  )

  const { mutate: registerUser, isLoading: isRegistering } = useMutation(
    registerUserFn,
    {
      onSuccess: (userId) => {
        setStep(4)
        setUserId(userId)
      },
    }
  )
  const { mutate: phoneMutate, isLoading: phoneLoading } = useMutation(
    addPhoneFn,
    {
      onSuccess: (uniqueStr) => {
        setUnique(uniqueStr)
        setStep(2)
      },
    }
  )

  function handleSubmit() {
    if (first_name.trim() === '' || last_name.trim() === '') {
      alert('First name or last name can`t be empty.')
      return
    }

    setStep(3)
  }
  function handleReset() {
    setPhoneCode('')
  }

  function handleResetValues(step: number) {
    if (step === 1) {
      setCountry_code(countries[0].phone)
      setPhone_number('')
    } else if (step === 3) {
      setFirst_name('')
      setLast_name('')
    } else if (step === 4 || step === 5) {
      setEmail('')
    }
  }

  function handleTermsModalOpen() {
    setIsTermsModalOpen(true)
  }
  function handleTermsModalClose() {
    setIsTermsModalOpen(false)
  }

  function handlePolicyModalOpen() {
    setIsPolicyModalOpen(true)
  }
  function handlePolicyModalClose() {
    setIsPolicyModalOpen(false)
  }

  return (
    <AuthLayout>
      <div className="w-[600px] h-[490px] p-8 bg-white rounded-[24px] overflow-hidden">
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ duration: 0.2 }}
        >
          <StepContext.Provider value={{ step, setStep }}>
            {step < 5 && (
              <RegisterStep
                handleReset={handleReset}
                handleResetValues={handleResetValues}
              />
            )}
            {step === 1 ? (
              <div className="h-[200px]">
                <div className="flex flex-row">
                  <Autocomplete
                    disableClearable
                    options={countries}
                    defaultValue={countries[0]}
                    getOptionLabel={(option) =>
                      `${option.flag} ${option.label} (+${option.phone})`
                    }
                    renderOption={(props, option) => (
                      <Typography {...props} className="text-xs cursor-pointer">
                        {option.flag} {option.label} (+{option.phone})
                      </Typography>
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        setCountry_code(value.phone)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-xs"
                        size="small"
                        label="Country Code"
                        variant="outlined"
                        InputLabelProps={{
                          className: 'text-xs',
                        }}
                        inputProps={{
                          ...params.inputProps,
                          className: 'text-[16px] px-[6px] py-[8px]',
                        }}
                      />
                    )}
                  />
                  <TextField
                    className="w-80 text-xs ml-4"
                    size="small"
                    value={phoneNumber}
                    onChange={(event) => {
                      setPhone_number(event.target.value)
                    }}
                    InputLabelProps={{
                      className: 'text-[16px] pt-[6px]',
                    }}
                    inputProps={{
                      className: 'text-[16px] px-[12px] py-[14px]',
                    }}
                    label="Phone Number"
                  />
                </div>
                <div
                  style={{
                    height: '150px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                  }}
                >
                  <Button
                    onClick={() => {
                      const phoneNumberWithoutLeadingZero =
                        phoneNumber.startsWith('0')
                          ? phoneNumber.slice(1)
                          : phoneNumber
                      phoneMutate({
                        phoneNumber: phoneNumberWithoutLeadingZero,
                        countryCode: `+${country_code}`,
                      })
                    }}
                    variant="contained"
                    className="w-[120px] p-[10px] bg-[#FB3B8C] text-white rounded-[12px] hover:bg-[#FA056C]"
                    disabled={!phoneNumber || !country_code}
                  >
                    {phoneLoading ? (
                      <Typography className="text-whiteText text-base/loose">
                        <CircularProgress
                          className="mt-0.5"
                          size={'16px'}
                          color="inherit"
                        />
                      </Typography>
                    ) : (
                      <Typography className="text-whiteText font-inter text-base/loose">
                        Next
                      </Typography>
                    )}
                  </Button>
                </div>
              </div>
            ) : step === 2 ? (
              <div>
                <div className="flex items-center">
                  <TextField
                    className="w-[254px]"
                    label="First Name"
                    value={first_name}
                    onChange={(event) => setFirst_name(event.target.value)}
                    InputLabelProps={{
                      className: 'text-[16px]',
                    }}
                    inputProps={{
                      className: 'text-[16px] px-[12px] py-[14px]',
                    }}
                  />
                  <TextField
                    className="ml-[28px] w-[254px]"
                    label="Last Name"
                    value={last_name}
                    onChange={(event) => setLast_name(event.target.value)}
                    InputLabelProps={{
                      className: 'text-[16px]',
                    }}
                    inputProps={{
                      className: 'text-[16px] px-[12px] py-[14px]',
                    }}
                  />
                </div>
                <div
                  style={{
                    height: '178px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                  }}
                >
                  <button
                    onClick={() => setStep(1)}
                    style={{
                      padding: '13px',
                      width: '120px',
                      background: 'white',
                      border: '1px solid #FB3B8C',
                      color: '#FB3B8C',
                      borderRadius: '12px',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Back
                  </button>
                  <Button
                    disabled={!first_name || !last_name}
                    className=" hover:bg-[#FA056C]"
                    onClick={handleSubmit}
                    style={{
                      marginLeft: '10px',
                      padding: '11px',
                      width: '120px',
                      backgroundColor: '#FB3B8C',
                      border: '1px solid #FB3B8C',
                      color: 'white',
                      borderRadius: '12px',
                      fontSize: '16px',
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : step === 3 ? (
              <div>
                <TextField
                  className="w-[100%] mb-10"
                  label="Email"
                  value={email}
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                  InputLabelProps={{
                    className: 'text-[16px]',
                  }}
                  inputProps={{
                    className: 'text-[16px] px-[12px] py-[14px]',
                  }}
                />
                <div style={{ color: '#797880' }}>
                  <p>
                    By signing up, you confirm that you have read and agreed to
                    our
                  </p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={handleTermsModalOpen}
                      sx={{
                        background: 'none',
                        fontSize: '16px',
                        padding: 0,
                        textDecoration: 'underline',
                        fontWeight: 500,
                        color: 'black',
                      }}
                    >
                      Terms of use
                    </Button>
                    <span>&nbsp;and&nbsp;</span>

                    <Button
                      onClick={handlePolicyModalOpen}
                      sx={{
                        background: 'none',
                        fontSize: '16px',
                        padding: 0,
                        textDecoration: 'underline',
                        fontWeight: 500,
                        color: 'black',
                      }}
                    >
                      Privacy policy.
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    height: '58px',
                  }}
                >
                  <button
                    onClick={() => setStep(2)}
                    style={{
                      padding: '13px',
                      width: '120px',
                      background: 'white',
                      border: '1px solid #FB3B8C',
                      color: '#FB3B8C',
                      borderRadius: '12px',
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    Back
                  </button>
                  <Button
                    disabled={!email}
                    className=" hover:bg-[#FA056C]"
                    onClick={() => {
                      isEmailValid
                        ? registerUser({
                            first_name,
                            last_name,
                            email,
                            phone_number: phoneNumber,
                            country_code: `+${country_code}`,
                          })
                        : alert('The email has an invalid format')
                    }}
                    style={{
                      marginLeft: '10px',
                      backgroundColor: '#FB3B8C',
                      color: 'white',
                      padding: '11px',
                      width: '120px',
                      border: '1px solid #FB3B8C',
                      borderRadius: '12px',
                      fontSize: '16px',
                    }}
                    title="Next"
                  >
                    Next
                  </Button>
                </div>
                <Modal open={isTermsModalOpen} onClose={handleTermsModalClose}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 600,
                      height: 600,
                      bgcolor: 'background.paper',
                      border: '2px solid #fff',
                      boxShadow: 24,
                      borderRadius: '24px',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        padding: '20px 28px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #DCDBE8',
                      }}
                    >
                      <h4 style={{ fontSize: '18px', fontWeight: 600 }}>
                        Terms of use
                      </h4>
                      <Button
                        sx={{ cursor: 'pointer', padding: 0, minWidth: '28px' }}
                        onClick={handleTermsModalClose}
                      >
                        <img width="28" height="28" src={Images.icCloseBtn} />
                      </Button>
                    </Box>
                    <Box
                      className="scroll"
                      sx={{
                        paddingX: 4,
                        scrollbarWidth: 'thin',
                        overflowY: 'auto',
                        height: '500px',
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {`PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR WEBSITE / APPLICATION ARE SUBJECT TO THE FOLLOWING TERMS. YOU SHOULD READ THROUGH ALL THE TERMS CAREFULLY AS THEY CONSTITUTE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND US. IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE WEBSITE / APPLICATION IN ANY MANNER.

These Terms of Use (the “Terms”) are a binding contract between you and URU Sec Ltd. (the “Company”, “we”, “us” or “our”). By accessing or using the Website – www.symania.com and/or Application, you acknowledge that you have read and understood and agree to comply with the terms and conditions below, and these Terms will remain in effect at all times while you use the Website / Application.

1. In these Terms the following terms have the meanings ascribed next to them:

1.1. Website / Application means our public Website available at www.symania.com and/or our Application available at Google Play & App Store providing marketing materials and professional information regarding our Services and Products, as well as access and use of our Authentication Service; for clarity, the term "Website / Application" includes all Content (defined below) on the Website / Application.

1.2. Content means visual, audio, numeric, graphical, text or other data or content, which is displayed or made available through the Website / Application or otherwise by us.

1.3. “User” means any natural person visiting and using our Website / Application.

1.4. “User Data” means any Personal Data and/or Non Personal Data, as such terms are defined in our Privacy Policy available at www.symania.com, Google Play and App Store (“Privacy Policy”), or other information relating to the User which is collected, processed or accessed while a User browses or uses the Website / Application.

2. The Website / Application may only be used in compliance with all applicable laws and for legitimate purposes. You may use the Website / Application only for your own non-commercial purposes and for no other purpose.

3. User Responsibility. User represents and warrants that: (i) the User Data and all other data you provide is accurate and complete, lawful and non-infringing.

(ii) your use or access of the Website / Application will not:

(a) infringe on the intellectual property rights of any third party or any rights of publicity or privacy; (b) violate any law, statue, ordinance or regulation (including without limitation the laws and regulations governing export control, unfair competition, antidiscrimination or false advertising); (c) create or cause any viruses, Trojan horses, worms, time bombs, cancelbots or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information; (d) create or cause situations or environments where any failure or time delays of, or errors or inaccuracies in, the Content, or information on any systems of any third party could lead to death, personal injury, or severe physical or environmental damage; (e) create or cause any damages, corruption, loss, interferences, security intrusions or any failure of any systems in your control, possession, or your business operations, or any systems of any third-party.

4. Restrictions. Except as expressly permitted in these Terms, you may not, and shall not allow any third party to: (i) give, sell, rent, lease, timeshare, sublicense, disclose, publish, assign, market, sell, display, transmit, broadcast, transfer or distribute any portion of the Website / Application or Content to any third party, including, but not limited to your affiliates; (ii) circumvent, disable or otherwise interfere with security-related features of the Website / Application or features that prevent or restrict use or copying of any Content or that enforce limitations on use of the Website / Application; (iii) reverse engineer, decompile or disassemble, decrypt or attempt to derive the source code of, the Website / Application, or any components thereof; (iv) copy, modify, translate, patch, improve, alter, change or create any derivative works of the Website / Application, or any part thereof; (v) use any robot, spider, scraper or other automated means to access or monitor the Website / Application or Content for any purpose; (vi) take any action that imposes or may impose (at the Company’s sole discretion) an unreasonable or disproportionately large load on the infrastructure which supports the Website / Application; (vii) interfere or attempt to interfere with the integrity or proper working of the Website / Application, or any related activities; (viii) use the Website / Application in any unlawful manner or for any harmful, irresponsible or inappropriate purpose, or in breach of these Terms. Without derogating from the above, each User agrees to be liable to the Company for any act or omission of any of its employees or anyone on its behalf using the Website / Application that would constitute a breach of these Terms if such acts or omissions were performed by the User.

5. Security. The Company agrees during the provision of the Website / Application to implement reasonable security measures to protect User Data and will, at a minimum, utilize industry standard security procedures, as described in our Privacy Policy available at www.symania.com, Google Play and App Store. However, because of the nature of the Internet, the Company cannot ensure and we shall not be held liable for that the Website / Application will be immune from any wrongdoings, malfunctions, unlawful interceptions or access, or other kinds of abuse and misuse (“Faults”) and the Company shall not be held liable for any damage caused as a result of your use of the Website / Application, it’s unavailability, any error or Faults in the Website / Application.

6. Links to Other Website / Applications. The Website / Application may provide links to the Website / Applications or services of other third-party sites. Links to such third-party sites do not constitute any endorsement by the Company of such third-party sites, or the quality, suitability or accuracy of the products, content, materials or information presented or made available by such sites. You acknowledge and agree that the Company, its directors, officers, employees, agents, service providers and representatives, other third parties, and each of their respective successors and assigns, are not responsible for any claims, damages or losses caused or alleged to have been caused by the use of any third-party sites, or from the products, content, material or information presented by or available through those sites.

7. Intellectual Property.

7.1. Website / Application and Content. Title and full, exclusive ownership rights in the Website / Application (and all parts of either, including the Content), and all reproductions, corrections, modifications, enhancements and improvements, and all related patent rights, copyrights, trade secrets, trademarks, service marks, related goodwill, including data related to your usage of the Website / Application (excluding User Data), are the exclusive property of the Company. You do not obtain any rights in our technology or intellectual property. You will not remove, alter, or obscure any proprietary notices (including copyright notices) of the Company on the Website / Application. Any suggestions, recommendations, improvements, inventions and feedback by you or by anyone on your behalf in connection with the Website / Application, and the produce of the Company are the sole property of the Company, irrespective of the creator.

7.2. User Data. The intellectual property and all other rights, title and interest of any nature in and to User Data are and shall remain your exclusive property. Except as expressly set forth herein, nothing in these Terms shall be construed as transferring any rights, title or interests in User Data to the Company or any third party.

8. Privacy Policy. You acknowledge and agree that we will use any Personal Data that we may collect or obtain in connection with the Website / Application in accordance with our Privacy Policy available at www.symania.com, Google Play and App Store. Other than our security obligations under Section ‎5, we assume no responsibility or liability for User Data, and you shall be solely responsible for User Data and the consequences of using, disclosing, storing, or transmitting it.

9. Warranty and Disclaimer

9.1. THE WEBSITE / APPLICATION AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. WE DO NOT WARRANT THAT THE WEBSITE / APPLICATION AND CONTENT WILL MEET USER’S REQUIREMENTS OR THAT THE CONTENT IS ACCURATE OR UP TO DATE. YOU ASSUME ALL RESPONSIBILITY FOR ACCESSING AND USING THE WEBSITE / APPLICATION AND CONTENT. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.

9.2. PURCHASES OF SERVICES ARE NOT CONDUCTED THROUGH THE WEBSITE / APPLICATION AND NOTHING IN THIS WEBSITE / APPLICATION CONSTITUTES A COMMITMENT OF OURS TO PROVIDE SERVICES TO ANY PERSON OR ENTITY WHICH VISITS THE WEBSITE / APPLICATION.

9.3. THE COMPANY DOES NOT WARRANT THAT THE ACCESS TO AND USE OF THE WEBSITE / APPLICATION WILL BE UNINTERRUPTED OR ERROR FREE, THAT ALL VULNERABILITIES AND DEFECTS WILL BE DETECTED, OR THAT THE WEBSITE / APPLICATION IS FREE FROM VIRUSES OR OTHER HARMFUL CODE.

YOU AGREE THAT WE WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES THAT MAY RESULT FROM TECHNICAL PROBLEMS INCLUDING WITHOUT LIMITATION IN CONNECTION WITH THE INTERNET (SUCH AS SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS) OR ANY TELECOMMUNICATIONS OR INTERNET PROVIDERS.

9.4. Applicable law may not allow the exclusion of certain warranties, so to that extent such exclusions may not apply.

10. Limitation of Liability. IN NO EVENT SHALL THE COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS AND REPRESENTATIVES, OTHER THIRD PARTIES, AND EACH OF THEIR RESPECTIVE SUCCESSORS AND ASSIGNS, BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE CLAIMS, LOSSES, OR DAMAGES, RELATED TO OR IN CONNECTION WITH THE WEBSITE / APPLICATION OR CONTENT MADE AVAILABLE ON OR THROUGH THE WEBSITE / APPLICATION, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING BUT NOT LIMITED TO ANY LOSS OF PROFITS, BUSINESS, OR ANTICIPATED SAVINGS, ANY LOSS OF, OR DAMAGE TO DATA, REPUTATION, REVENUE OR GOODWILL AND/OR THE COST OF PROCURING ANY SUBSTITUTE GOODS OR SERVICES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS AND REPRESENTATIVES SHALL NOT BE LIABLE FOR ANY CLAIMS, LOSSES, OR DAMAGES IN CONNECTION WITH ERRORS, OMISSIONS OR INACCURACIES OF THE CONTENT, OR WEBSITE / APPLICATION OR ANY DECISION MADE IN RELIANCE ON THE CONTENT CONTAINED ON OR ACCESSIBLE THROUGH THE WEBSITE / APPLICATION. YOUR SOLE REMEDY FOR ANY CLAIMS IN CONNECTION WITH THE WEBSITE / APPLICATION IS TO DISCONTINUE USING THE WEBSITE / APPLICATION AND THE RELATED CONTENT.

THE FOREGOING EXCLUSIONS AND LIMITATIONS ON LIABILITY SET FORTH IN THIS SECTION ‎10 SHALL APPLY: (I) EVEN IF THE COMPANY, ITS AFFILIATES OR THIRD-PARTY PROVIDERS, HAVE BEEN ADVISED, OR SHOULD HAVE BEEN AWARE, OF THE POSSIBILITY OF LOSSES OR DAMAGES; (II) EVEN IF ANY REMEDY IN THESE TERMS FAILS OF ITS ESSENTIAL PURPOSE; AND (III) REGARDLESS OF THE THEORY OR BASIS OF LIABILITY (SUCH AS, BUT NOT LIMITED TO, BREACH OF CONTRACT OR TORT).

NO ACTION, REGARDLESS OF FORM, MAY BE BROUGHT BY YOU AFTER THE EARLIER OF (A) 12 MONTHS AFTER THE DATE ON WHICH YOU BECOME AWARE THAT A CAUSE OF ACTION HAS ARISEN, OR (B) THE PERIOD SET FORTH UNDER THE APPLICABLE STATUTE OF LIMITATIONS.

11. Indemnification by User. User shall indemnify, defend and hold harmless the Company, its affiliates and its and their respective officers, directors and employees from and against any and all claims, damages, actions and causes of action in connection with the Website / Application, or in connection with any distribution, publication, exportation, disclosure, misuse or misconduct by User, or fraudulent behavior by User.

12. Modifications to Website / Application/Content. You acknowledge and agree that the Company reserves the right to change, modify, amend, suspend or discontinue any aspect of the Website / Application or Content at any time, without notice and without liability to you or any other third party. If you do not agree to the new or different terms, you should not use and are free to discontinue using the Website / Application.

13. Termination. The Company may terminate your use of the Website / Application at any time and without prior written notice in any case you have breached these Terms. We shall not be liable to you or any third party for termination of your use of or access to the Website / Application, or any portion thereof. Any provision of these Terms which is intended to survive termination shall survive termination of these Terms.

14. Severability. If any provision of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision shall be enforced to the maximum extent permissible so as to affect the intent of these Terms, and the remainder of these Terms shall continue in full force and effect.

15. Choice of Law and Venue. This Agreement shall be governed by and interpreted in accordance with laws of the State of Israel, without giving effect to any conflict of law provisions. The exclusive jurisdiction shall vest with the competent courts in the city of Tel Aviv-Yafo, Israel.

16. General. (i) These Terms and the Privacy Policy represent the complete agreement concerning the Website / Application between you and the Company. (ii) Section headings are provided for convenience only and have no substantive effect on construction. (iii) The failure of the Company to exercise or enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision. (iv) Th ese Terms may not be assigned by User without our prior written consent.

17. Symania, also known as URU SEC Ltd. is a system entity that creates, maintains, and manages identity information for principals and provides authentication services to relying applications within a federation or distributed network.
Symania offers user authentication as a service.
Relying party applications, such as web applications, outsource the user authentication step to a trusted identity provider;
Message frequency varies;
Message and data rates may apply;
Text HELP to (+1) 2076306532 for help; Reply STOP to cancel;
Carriers are not liable for any delays or undelivered messages

Copyright © 2020, URU Sec Ltd. All rights reserved.

Last Updated: January 26, 2021
      `}
                      </Typography>
                    </Box>
                  </Box>
                </Modal>
                <Modal
                  open={isPolicyModalOpen}
                  onClose={handlePolicyModalClose}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 600,
                      height: 600,
                      bgcolor: 'background.paper',
                      border: '2px solid #fff',
                      boxShadow: 24,
                      borderRadius: '24px',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        padding: '20px 28px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #DCDBE8',
                      }}
                    >
                      <h4 style={{ fontSize: '18px', fontWeight: 600 }}>
                        Privacy policy
                      </h4>
                      <Button
                        sx={{ cursor: 'pointer', padding: 0, minWidth: '28px' }}
                        onClick={handlePolicyModalClose}
                      >
                        <img width="28" height="28" src={Images.icCloseBtn} />
                      </Button>
                    </Box>
                    <Box
                      className="scroll"
                      sx={{
                        paddingX: 4,
                        scrollbarWidth: 'thin',
                        overflowY: 'auto',
                        height: '500px',
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {`PRELIMINARY This Privacy Policy explains our privacy practices for processing Personal Data on our Website / Application and Services. We may process your Personal Data when you visit and browse our Website and use our Application and Services, as described in this Policy.

All capitalized terms shall have the meanings as defined below.

We are committed to protecting your privacy and processing your Personal Data fairly and lawfully in compliance with applicable data protections laws. You can access our full Privacy Policy below to help you understand better how we collect and use your Personal Data. In it, we explain in more detail the types of Personal Data we collect, how we collect it, what is the legal basis of collection, what we may use it for, who we may share it with, what our retention periods are and what are your rights in relation to the Personal Data we collect.

Within the Privacy Policy you will find some specific examples of why and how we use your Personal Data.

Read this policy and make sure you fully understand our practices in relation to your Personal Data, before you access or use the Website / Application or Services. By browsing this Website and/or use our Application and Services, you consent to the processing of your Personal Data as detailed in this Privacy Policy. If you have read this Privacy Policy, and remain opposed to our practices, you must immediately discontinue all use of the Website / Application and/or the Services. If you have further questions or concerns regarding this policy please contact us at: support@symania.com.

PRIVACY POLICY

URU Sec Ltd. (“Symania”, “we”, “our” or “us”) provides this Privacy Policy, as will be updated from time to time (our “Policy” or “Privacy Policy”) to inform the Visitors and users of our Website / Application (or “you”) of our policies and procedures regarding the collection, use and disclosure of information we receive when you use the Website / Application and/or the Services.

1. Definitions:

“Applicable Laws” shall mean any applicable privacy or other law, to the extent applicable to Symania.

“Personal Data” means individually identifiable information, namely information that identifies an individual or may with reasonable efforts cause the identification of an individual.

“Non-Personal Data” means information that does not personally identify you and does not reveal your specific identity as an individual, such as anonymized information. “Services” means the Services of Authentication.

“you” means visitors of our Website and/or users of our Application and Services.

“Website / Application” means our public Website available at www.symania.com and our Application available at Google Play and App Store providing information regarding the services we provide to clients.

This Policy was originally written in English. If you are reading a translation and it conflicts with the English version, please note that the English version prevails.

2. When Does This Privacy Policy Apply

This Privacy Policy applies to Personal Data about you that we collect, use or otherwise process regarding your relationship with us as a user of our Website / Application and/or Services. This Privacy Policy does not apply to our other services that may have separate privacy policies that do not incorporate this Policy.

3. The Types Of Personal Data That We Collect

Personal Data That You Provide To Us Information provided through the “Contact Us” form: While browsing our Website / Application or using our Services, you may provide us Personal Data if you wish us to contact you and provide more information regarding our services, including: full name, email, company phone number and/or personal phone number, position in workplace and name of workplace, company and/or personal address including city, state/ region, postal code (“Contact Details”). You may choose to provide us additional Personal Data through the “free text” section in our contact form. Please do not provide further Personal Data than is required for us to contact you.

The Personal Data That We Collect or Generate If you browse our Website / Application and/or use of Services, we may collect your Personal Data.

4. The Types Of Non-Personal Data That We Collect Or Generate

In addition to the categories of Personal Data described above, we may also process further anonymized information and data that is not processed by reference to a specific individual. We may collect this Non-Personal Data through the Website / Application and/or the interface of the Services in the following ways:

a) Information that your browser and/or device sends (“Log Data”). This Log Data may include, but is not limited to, non-identifying information regarding your device, operating system, internet browser type, screen resolution, language and keyboard settings, internet service provider, the web page you were visiting and information you search, etc.

b) We may use automated devices and applications to evaluate usage of our Website / Application and/or Services. We use these tools to help us improve our Website / Application and Services, performance and user experience. We may also engage third parties to track and analyze data or provide other services on our behalf. Such third parties may combine the information that we provide about you with other information that they have collected from other sources. This Policy does not cover such third parties’ use of the data and such use is governed by such third parties’ privacy policies. For more information, please see Section ‎7 “Sharing Information with Others” below. 5. How We Use Personal Data Personal Data is used for the following primary purposes (as may be updated from time to time):

a) to provide and operate the Website / Application and the Services;

b) to monitor and analyze use of the Website / Application and the Services and study and analyze the functionality of the Website / Application;

c) to provide service announcements and notices, promotional messages and market the our services;

d) to enforce our Terms of Use, policies and other contractual arrangements and prevent misuse of the Website / Application and the Services; and to comply with court orders and warrants and to take any action in any legal dispute and proceeding;

e) to better understand your needs, both on an aggregated and individualized basis, in order to further develop, customize and improve our Website / Application and the Services based on users’ preferences, experiences and difficulties;

f) to communicate with you and contact you to obtain feedback from you regarding the Website / Application and the Services;

g) to disclose to third party vendors, service providers, contractors or agents who perform functions on our behalf with respect to the Website / Application and the Services;

h) to create aggregated statistical data and other aggregated and/or inferred Non-Personal Data, which we or our business partners may use to provide and improve our Website / Application and the Services; and i) as otherwise authorized by you.

We may use your email address you provided us to contact you when necessary, including in order to send you reminders, offers and to provide you information and notices about the Website / Application or the Services. At any time, you may choose (opt out) whether your Personal Data is to be used for sending such marketing materials which are not an essential part of the usage of the Website / Application or the Services. You may exercise your choice or request further information, by contacting us at: support@symania.com. 6. How We Use Non-Personal Data We may use information that is Non-Personal Data for the same purposes we use Personal Data (where applicable) and in addition in order to:

a) compile anonymous or aggregate information;

b) disclose to third party vendors, service providers, contractors or agents who perform tasks on our behalf in connection with the Website / Application or the Services;

c) monitor and analyze use of the Website / Application and the Services and for the technical administration and troubleshooting of the use of the Website / Application and the Services; and d) provide us with statistical data.

7. Sharing Information With Others

We do not sell, rent or lease your Personal Data. We may share your Personal Data with our, service providers and other third parties, if necessary to fulfil the purposes for collecting and processing the information, such as cloud vendors, subcontractors providing us Processing services, etc. provided that any such third party will commit to protect your privacy as required under the Applicable Laws and this Policy.

Additionally, a merger, acquisition or any other structural change may require us to transfer your Personal Data to another entity, provided that the receiving entity will comply with this Policy.

We may need to disclose Personal Data in response to lawful requests by public authorities or law enforcement officials, including meeting national security or law enforcement requirements. We cooperate with government and law enforcement officials to enforce and comply with the law.

8. Transfer Of Data Outside Your Territory

We may store, process or maintain information in various sites worldwide, including through cloud based service providers worldwide. We may transfer your Personal Data to our subsidiaries or third parties in locations around the world for the purposes described in this Privacy Policy. Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of your personal information.

If you are located in a jurisdiction where transfer of your Personal Data to another jurisdiction requires your consent, then you provide us your express and unambiguous consent to such transfer or the storage, processing or maintenance of the information in other jurisdictions by using the Website / Application.

9. Your Rights; “Do Not Track” Requests

In all of the above cases in which we collect, use or store your Personal Data, you may have certain rights set out in the laws of your jurisdiction and, in most cases, you can exercise them free of charge. To the extent required by Applicable Law, you may contact us at: support@symania.com and request to know what Personal Data we keep about you, in which case we will make good-faith efforts to locate the data that you request to access.

When you ask us to exercise any of your rights under this Policy and Applicable Law, we may need to ask you to provide us certain credentials to make sure that you are who you claim you are, to avoid disclosure to you of Personal Data related to others and to ask you questions to better understand the nature and scope of data that you request to access.

We may redact from the data which we will make available to you, any Personal Data related to others and/or as required by Applicable Law. Given the complexity and technical challenges associated with enforcing “do not track” requests, we generally do not comply with such requests. 10. Data Security

We take the safeguarding of the Personal and Non Personal Data very seriously, and use a variety of industry standard systems, applications and procedures to protect the information from loss, theft, damage or unauthorized use or access. However, although we make efforts to protect your privacy, we cannot guarantee that the Website / Application and the Services will be immune from any wrongdoings, malfunctions, unlawful interceptions or access, or other kinds of abuse and misuse.

We also regularly monitor our systems for possible vulnerabilities and attacks, and regularly seek new ways and for further enhancing the security of our Website / Application and Services and protection of our users’ privacy.

You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. In addition, you should take steps to protect against unauthorized access to Personal Data stored on your premises as well as defining limited access rights to such information on a need to know basis.

If you receive an e-mail asking you to update your information with respect to the Website / Application or the Services, do not reply and please contact us at support@symania.com.

11. Data Retention

We retain different types of information for different periods, depending on the purposes for processing the data. We may retain Personal Data for as long as necessary in order to support our legitimate business purposes, for example, for storing data, for documentation, for cyber-security management purposes, legal proceedings and tax issues. We may store aggregated Non Personal Data without time limit. In any case, as long as you use the Website / Application, we will keep information about you, unless we are legally required to delete it, or if you exercise your rights to delete the information.

12. Our Policy Toward Children Our Website / Application and/or the Services are not meant to be used by or for persons under 18, as such, we do not knowingly collect Personal Data from minors younger than 18. If this age requirement is not met, you are required to obtain the consent of the parent or guardian to provide and process information in accordance with this Policy;

lacking such consent, please do not use the Website / Application or the Services.

13. Changes To This Privacy Policy

We may change the terms of this Privacy Policy from time to time by posting notice on our Website / Application, with a seven (7) day advance notice. However, substantial changes will be effective thirty (30) days after the notice was initially posted. We will make an effort to inform you of substantial changes through the channels of communication generally used in such circumstances.

If we need to adapt the Policy to legal requirements, the amended Policy will become effective immediately or as required.

Your continued use of the Website / Application following such notice shall constitute your consent to any changes made and a waiver of any claim or demand in relation to such changes. If you do not agree to the new or different terms, you should not use and are free to discontinue using the Website / Application.

14. Applicable Law And Dispute Resolution This Privacy Policy shall be governed by and construed in accordance with the laws of the State of Israel, without regard to its conflict of law provisions.

The courts located in the city of Tel-Aviv, Israel, shall have exclusive jurisdiction over all disputes and proceedings arising from this Privacy Policy.

15. Contact Us For further information about this Policy, please contact us at: support@symania.com.

If you have any concerns relating to this Policy, please contact us and we will make good-faith efforts to address your concerns. We are usually able to resolve privacy questions or concerns promptly and effectively. If you are not satisfied with the response you receive from us, you may escalate concerns to the applicable privacy regulator in your jurisdiction. Upon request, we will provide you with the contact information for that regulator.

No mobile information will be shared with third parties/affiliates for marketing/promotional purposes.
All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.

Copyright © 2020, URU Sec Ltd. All rights reserved.

Last Updated: January 26, 2021
      `}
                      </Typography>
                    </Box>
                  </Box>
                </Modal>
              </div>
            ) : step === 4 ? (
              <div>
                <Button
                  onClick={() => {
                    return emailMutate({ email, userId })
                  }}
                  style={{
                    padding: '8px 14px',
                    marginTop: '-12px',
                    background: 'white',
                    border: '1px solid #303030',
                    color: '#303030',
                    borderRadius: '12px',
                    fontSize: '16px',
                  }}
                >
                  <img width="24" height="24" src={Images.icRefresh} />
                  <span style={{ marginLeft: '8px', color: '#303030' }}>
                    Resend link
                  </span>
                </Button>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    height: '165px',
                  }}
                >
                  <Button
                    onClick={() => {
                      navigate(-1)
                      return
                    }}
                    style={{
                      padding: '11px 32px',
                      background: '#FB3B8C',
                      border: '1px solid #FB3B8C',
                      color: 'white',
                      borderRadius: '12px',
                      fontSize: '16px',
                    }}
                  >
                    Back to login
                  </Button>
                </div>
              </div>
            ) : null}
            {/* {step === 1 && <RegisterForm onSubmit={handleRegistered} />}
      {step === 2 && (
        <SelectSymbol onSubmit={handleNextStep} userId={userId} />
      )}
      {step === 3 && (
        <SecurityQuestionsForm onSubmit={handleNextStep} userId={userId} />
      )}
      {step === 4 &&
        (location.state?.activateToken ? (
          <Navigate
            to={`/activate-group-user/${location.state.activateToken}`}
          />
        ) : (
          <Navigate to="/login" />
        ))*/}
          </StepContext.Provider>
        </motion.div>
      </div>
    </AuthLayout>
  )
}

export default RegisterPage
