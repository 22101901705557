import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { getUserDetailsFn } from 'src/api'
import { queryClient } from 'src/query-client'

interface UserDetails {
  image: string | null
  firstName: string
  lastName: string
  countryCode: string
  phoneNumber: number
  email: string
  orgs: any[]
  selfDeletedAt?: Date
  regPhase?: number | null
}

interface UserDetailsContextValue {
  userDetails: UserDetails | undefined
  refetchUserDetails: () => void
}

const UserDetailsContext = createContext<UserDetailsContextValue>({
  userDetails: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchUserDetails: () => {},
})

export function useUserDetails() {
  return useContext(UserDetailsContext)
}

interface UserDetailsProviderProps {
  userId: string
  children: React.ReactNode
}

export function UserDetailsProvider({
  userId,
  children,
}: UserDetailsProviderProps) {
  const { data: userDetails } = useQuery({
    queryKey: ['userDetails', userId],
    queryFn: () => getUserDetailsFn({ userId }),
  })

  const value: UserDetailsContextValue = {
    userDetails,
    refetchUserDetails: () => {
      queryClient.invalidateQueries({ queryKey: ['userDetails'] })
    },
  }

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  )
}
